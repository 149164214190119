<template>
    <nuxt-link
        class="polished card btn-card"
        :to="to"
        :class="{
            lower,
            elevate: !lower
        }"
        :target="target || '_self'"
    >
        <slot></slot>
    </nuxt-link>
</template>

<script>
export default {
    props: ['lower', 'to', 'target'],

    computed: {

    }
}
</script>
