<i18n>
{
    "uk": {
        "trial": {
            "title": "Тиждень повного доступу",
            "price": "Безплатно",
            "text": "Всі можливості платформи<br> без обмежень",
            "action": "Спробувати"
        },
        "plan": {
            "title": "Навчайтесь з нами",
            "price": "від <span class=\"fw-800\">{value}</span> ₴/міс.",
            "text": "Доступ до десятків курсів про графічний дизайн, вебдизайн, UI/UX та фронтенд",
            "action": "Підібрати тариф"
        }
    },
    "en": {
        "trial": {
            "title": "Full access week",
            "price": "For free",
            "text": "All features of the platform<br> are unlimited",
            "action": "Try"
        },
        "plan": {
            "title": "Learn with us",
            "price": "from <span class=\"fw-800\">{value}</span> ₴/mo.",
            "text": "Access to dozens of courses on graphic design, web design, UI/UX and frontend",
            "action": "Choose a plan"
        }
    }
}
</i18n>

<template>
    <div class="d-flex justify-content-center options">
        <Card class="trial option p-h">
            <PurpleGradient  class="polished"/>

            <div class="relative ta-center option-content">
                <WebpPicture
                    class="trial-image elements"
                    imgClass="block-image"
                    src="https://cdn.cases.media/image/original/sub-trial-lg.png"
                    width="1105"
                    height="576"
                    loading="lazy"
                    alt=""
                />

                <div class="title fw-800">{{ $t('trial.title', locale) }}</div>
                <div class="price-size free tt-upper">{{ $t('trial.price', locale) }}</div>
                <div class="text" v-html="$t('trial.text', locale)" />
                <PanelButton
                    class="btn-bg fw-700 tt-upper d-inline-flex mt-auto action"
                    name="subscription-trial"
                >
                    {{ $t('trial.action', locale) }}
                </PanelButton>
            </div>
        </Card>
        <RainbowCard class="plan option ">
            <div class="option-content ta-center relative">
                <WebpPicture
                    class="plan-image elements"
                    imgClass="block-image"
                    src="https://cdn.cases.media/image/original/sub-plan-lg.png"
                    width="1094"
                    height="411"
                    loading="lazy"
                    alt=""
                />

                <div class="title fw-800">{{ $t('plan.title', locale) }}</div>
                <div class="price price-size" v-html="$t('plan.price', {
                    value: platformPricing.subscriptionReadOnly || 250,
                    locale,
                })" />
                <div class="text">{{ $t('plan.text', locale) }}</div>
                <nuxt-link
                    :to="localePath('/learning/subscription')"
                    class="btn-primary rainbow-button fw-700 tt-upper mt-auto action"
                >
                    {{ $t('plan.action', locale) }}
                </nuxt-link>
            </div>
        </RainbowCard>
    </div>
</template>

<script>
import PurpleGradient from '~/components/bg/PurpleGradient.vue';
import RainbowCard from '~/components/cards/RainbowCard.vue';
import PanelButton from '~/components/panels/PanelButton.vue';
import WebpPicture from '~/components/WebpPicture.vue';
import Card from '~/components/cards/Card.vue';

import { platformPricing } from '~/utilities/pricing';

export default {
    props: ['localeToUse'],

    components: {
        PurpleGradient,
        RainbowCard,
        PanelButton,
        WebpPicture,
        Card
    },

    data () {
        return {
            platformPricing,
        };
    },

    computed: {
        locale () {
            return this.localeToUse || this.$i18n.locale
        }
    }
}
</script>

<style scoped lang="postcss">
    .options {
        flex-direction: column;
    }

    option {
        height: 268px;
    }

    .trial {
        color: #fff;
        position: relative;

        .option-content  {
            filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.15));
            padding: 107px 16px 16px;
        }
    }

    .plan {
        margin-top: 32px;
    }

    .relative {
        position: relative;
    }

    .option {
        max-width: 504px;
        border-radius: 16px;
        flex-basis: 50%;
        padding: 16px;
    }

    .option-content {
        padding: 102px 14px 14px;
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
    }

    .price-size {
        font-size: 32px;
        line-height: 36px;
        margin: 4px 0;
    }

    .price {
        font-feature-settings: 'pnum' on, 'lnum' on;

        background: linear-gradient(109.35deg, #ED4A79 29.16%, #F4912C 87%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        display: inline-block;
    }

    .polished {
        border-radius: 16px;
    }

    :deep(.gradient-card) {
        padding: 2px;
    }

    :deep(.gradient-card-body) {
        border-radius: 14px;
        height: 100%;
    }

    .rainbow-button {
        color: #fff;
        background: radial-gradient(81.65% 81.65% at 14.06% 18.35%, #726FB6 0%, #AA5996 29.69%, #ED4A79 65.62%, #F4912C 100%);
    }


    .text {
        margin-bottom: 16px;
        font-size: 12px;
        line-height: 16px;
    }

    .btn-bg {
        background-color: #fff;
        color: var(--color-cases-black);

        &:hover {
            color: #595e69;
        }
    }

    .action {
        width: 100%;
        min-height: 32px;
    }

    .elements {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
    }

    .trial-image {
        top: -56px;
        left: -20px;
        right: -21px;
        min-height: 192px;

        :deep(img) {
            width: 100%;
            max-width: 382px;
        }
    }

    .plan-image {
        top: -54px;
        left: -25px;
        right: -18px;
        min-height: 173px;

        :deep(img) {
            width: 100%;
            max-width: 380px;
        }
    }

    @media screen and (min-width: 576px) {
        .options {
            flex-direction: row;
        }

        .plan {
            margin-top: 0px;
            margin-left: 24px;
        }
    }

    /*  Exception for pages with side menu */
    /* Gosh i wish @container were usable... */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        .options.sided {
            flex-direction: column;

            .plan {
                margin-top: 32px;
                margin-left: 0px;
            }
        }
    }

    /*  Non compact version  LG */
    @media screen and (min-width: 992px) {
        .options:not(.compact) {
            flex-direction: row;

            .option {
                height: 340px;
            }


            :deep(.gradient-card) {
                padding: 4px;
            }

            :deep(.gradient-card-body) {
                border-radius: 12px;
            }

            .trial-image {
                top: -75px;
                left: -28px;
                right: -20px;
                min-height: 284px;

                :deep(img) {
                    max-width: none;
                }
            }

            .plan-image {
                top: -47px;
                left: -26px;
                right: -17px;
                min-height: 173px;

                :deep(img) {
                    max-width: none;
                }
            }

            .option-content {
                padding: 142px 28px 28px !important;
            }

            .title {
                font-size: 19px;
                line-height: 24px;
            }

            .price-size {
                font-size: 32px;
                line-height: 36px;
            }

            .free {
                font-weight: 800;
            }

            .text {
                margin-bottom: 16px;
                font-size: 16px;
                line-height: 120%;
                max-width: none;
            }

            .action {
                padding-top: 16px;
                padding-bottom: 16px;
                width: 240px;
                min-height: 48px;
            }
        }
    }

        /*  Non compact version */
    @media screen and (min-width: 1200px) {
        .options:not(.compact) {
            .option {
                height: 416px;
            }

            .option-content {
                padding: 168px 28px 28px !important;
            }

            .title {
                font-size: 24px;
                line-height: 28px;
            }

            .price-size {
                font-size: 40px;
                line-height: 48px;
                margin: 8px 0;
            }

            .action {
                width: 276px;
            }

            .text {
                margin-bottom: 24px;
                font-size: 20px;
                line-height: 24px;
            }
        }
    }
</style>
