var render = function render(){var _vm=this,_c=_vm._self._c;return _c('portal',{attrs:{"to":"cinema"}},[_c('div',[_c('transition',{attrs:{"name":"cinema"}},[(_vm.on)?_c('div',{directives:[{name:"bsl",rawName:"v-bsl",value:(_vm.on),expression:"on"}],staticClass:"cinema"},[_c('div',{staticClass:"cinema-container"},[_c('div',{staticClass:"controls"},[_c('button',{staticClass:"btn-close btn-close",attrs:{"title":`${_vm.$t('general.exit')} (ALT + ESC)`},on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_c('span',{staticClass:"icon-ic-dismiss"})])]),_vm._v(" "),_c('div',{ref:"screen",staticClass:"screen",class:{
                            vertical: _vm.isVerticalVideo
                        }},[(_vm.useProvider == 'vimeo')?_c('vimeo-player',{key:`video-${_vm.isVerticalVideo}`,ref:"player",staticClass:"video-wrapper",attrs:{"video-id":_vm.src.src,"options":_vm.vimeoOptions},on:{"ready":_vm.onVideoReady,"loaded":_vm.vimeoLoaded,"play":_vm.playHandler,"playing":function($event){return _vm.progress()},"pause":_vm.pauseHandler}}):_vm._e(),_vm._v(" "),(_vm.useProvider == 'youtube')?_c('div',[_c('youtube',{ref:"player",staticClass:"video-wrapper",attrs:{"player-vars":_vm.youtubeVars,"fitParent":true,"resize":true},on:{"ready":function($event){_vm.onVideoReady(); _vm.youtubeLoaded()},"playing":function($event){_vm.progress(); _vm.playHandler()},"paused":_vm.pauseHandler}})],1):_vm._e()],1),_vm._v(" "),(_vm.playlist.length && _vm.playlist.length > 1)?_c('div',{ref:"playlist",staticClass:"playlist",attrs:{"id":"playlist"}},[_c('div',{staticClass:"placeholder"}),_vm._v(" "),_c('div',{ref:"pointer",staticClass:"play-indicator icon-ic-play",style:({transform: `translateY(${_vm.pointerPos}px)`})}),_vm._v(" "),_vm._l((_vm.playlist),function(video,i){return _c('div',{key:i,ref:"playlistItem",refInFor:true,staticClass:"playlist-item f-small",class:{active: i == _vm.currentIndex},attrs:{"id":`list-item-${i}`},on:{"click":function($event){return _vm.changeVideo(i)}}},[_c('div',{staticClass:"poster",style:({
                                    backgroundImage: video.sources[0].provider == 'youtube' ?
                                                    `url(https://img.youtube.com/vi/${video.sources[0].src}/0.jpg)` :
                                                    `url(${video.poster})`
                                })}),_vm._v(" "),_c('div',{staticClass:"title fw-600"},[_vm._v(_vm._s(video.title || 'Без назви'))])])}),_vm._v(" "),_c('div',{staticClass:"placeholder"})],2):_vm._e()])]):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }