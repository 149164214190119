<i18n>
{
    "uk": {
        "defaultTitle": "Часто задавані запитання"
    },

    "en": {
        "defaultTitle": "Frequently Asked Questions"
    }
}
</i18n>


<template>
    <div>

        <h2 :class="titleClass || `h4 h3-md fw-800 mt-0 mb-4`">
            {{ title || $t('defaultTitle', locale) }}
        </h2>

        <div class="collapse-accordeon">
            <div
                v-for="(pair, i) in computedPairs"
                :key="`faq-pair-${i}`"
                class="collapse-panel collapse-panel-plain"
            >

                <CollapseButton
                    class="collapse-header"
                    :target="`faq-question-${i}`"
                    :collapsed="i != 0"
                >
                    {{ pair.question }}

                    <span class="collapse-state">
                        <span class="collapse-state-icon icon-ic-arrow-simple" :class="iconColor"></span>
                    </span>
                </CollapseButton>

                <Collapse
                    :id="`faq-question-${i}`"
                    group="faq"
                    :collapsed="i != 0"
                >
                    <div class="text-wrapper">
                        <p
                            v-for="(line, j) in pair.answer"
                            :key="`faq-pair-${i}-answer-${j}`"
                            v-html="line"
                        ></p>
                    </div>
                </Collapse>

            </div>
        </div>


        <script v-if="structuredData" type="application/ld+json" v-html="structuredData" />

    </div>
</template>


<script>
    export default {
        props: {
            title:      String,
            titleClass: String,
            iconColor:  String,
            pairs:      Array,
            locale:     String
        },

        components: {
            Collapse: () => import('~/components/collapse/Collapse.vue'),
            CollapseButton: () => import ('~/components/collapse/Button.vue')
        },

        computed: {
            computedPairs () {
                if (!this.pairs || this.pairs.length == 0) return [];

                return this.pairs.map(pair => {
                    let ret = Object.create(pair);

                    if (typeof ret.answer === 'string') ret.answer = [ret.answer];

                    return ret;
                });
            },

            structuredData () {
                let obj = {
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": []
                }

                this.computedPairs.forEach(pair => {
                    let answer = pair.answer;
                    if (Array.isArray(answer)) answer = answer.join(' ');
                    obj.mainEntity.push({
                        "@type": "Question",
                        "name": pair.question,
                        "acceptedAnswer": {
                            "@type": "Answer",
                            "text": answer
                        }
                    })
                });

                return obj;
            }
        }
    }
</script>


<style lang="postcss" scoped>
    .collapse-panel-plain {
        margin-right: 0;
        margin-left:  0;

        & + .collapse-panel-plain {
            margin-top:  16px;
            padding-top: 16px;
        }

        .collapse-header {
            font-weight: 700;

            padding:       0;
            padding-right: 32px;

            &:hover {
                background-color: transparent;
            }

            .collapse-state {
                right: 4px;
            }
        }

        :deep(.collapse-inner) {
            padding:       0;
            padding-right: 32px;
        }
    }

    .text-wrapper {
        > p:last-child {
            margin-bottom: 0;
        }
    }
</style>
