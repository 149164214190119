var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
        'scroll-side-wrapper': true,
        'scroll-no-offset': !_vm.offset,
        scrolling: _vm.scrolling
    }},[_c('button',{staticClass:"icon-ic-arrow-simple arrow-left btn-primary",class:{
            show: _vm.leftArrow
        },attrs:{"title":_vm.$t('scrollBack')},on:{"click":function($event){return _vm.scrollNext('left')}}},[_c('span',{staticClass:"icon-next"})]),_vm._v(" "),_c('div',{ref:"container",staticClass:"material-thumbnails side-scroll",style:({
            'scroll-padding-left' : _vm.paddingLeft,
            'scroll-padding-right' : _vm.paddingRight
        })},[(_vm.mist)?_c('mist',{staticClass:"side-scroll-mist inverted show"}):_vm._e(),_vm._v(" "),(_vm.paddingLeft)?_c('div',{staticClass:"left-placeholder",style:({
                'flex-basis': _vm.paddingLeft
            })}):_vm._e(),_vm._v(" "),_vm._t("default"),_vm._v(" "),(_vm.paddingRight)?_c('div',{staticClass:"right-placeholder",style:({
                'flex-basis': _vm.paddingRight
            })}):_vm._e(),_vm._v(" "),(_vm.mist)?_c('mist',{staticClass:"side-scroll-mist show"}):_vm._e()],2),_vm._v(" "),_c('button',{staticClass:"icon-ic-arrow-simple arrow-right btn-primary",class:{
            show: _vm.rightArrow
        },attrs:{"title":_vm.$t('scrollNext')},on:{"click":function($event){return _vm.scrollNext('right')}}},[_c('span',{staticClass:"icon-next"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }