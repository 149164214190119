import SideScroll from '~/components/SideScroll.vue';
import WebpPicture from '~/components/WebpPicture.vue';
export default {
  __name: 'Instruments',
  props: {
    locale: String,
    title: String,
    description: String
  },
  setup: function setup(__props) {
    var props = __props;
    var courses = ref([{
      class: 'figma',
      link: '/learning/course/figma-essentials/about',
      category: 'web-ui-ux',
      icon: 'https://cdn.cases.media/image/large/sub-landing-course-icon-figma.png',
      title: 'Figma'
    }, {
      class: 'ps',
      link: '/learning/course/photoshop-basics/about',
      category: 'graphic-design',
      icon: 'https://cdn.cases.media/image/large/sub-landing-course-icon-ps.png',
      title: 'Adobe Photoshop'
    }, {
      class: 'ae',
      link: '/learning/course/after-effects-basics/about',
      category: 'motion-design',
      icon: 'https://cdn.cases.media/image/large/sub-landing-course-icon-ae.png',
      title: 'After Effects'
    }, {
      class: 'ai',
      link: '/learning/course/illustrator-basics/about',
      category: 'vector-graphics',
      icon: 'https://cdn.cases.media/image/large/sub-landing-course-icon-ai.png',
      title: 'Adobe Illustrator'
    }, {
      class: 'html',
      link: '/learning/course/frontend-basics/about',
      category: 'frontend',
      icon: 'https://cdn.cases.media/image/large/sub-landing-course-icon-html.png',
      title: 'HTML'
    }, {
      class: 'css',
      link: '/learning/course/frontend-basics/about',
      category: 'frontend',
      icon: 'https://cdn.cases.media/image/large/sub-landing-course-icon-css.png',
      title: 'CSS'
    }, {
      class: 'id',
      link: '/learning/course/indesign-basics/about',
      category: 'layout',
      icon: 'https://cdn.cases.media/image/large/sub-landing-course-icon-id.png',
      title: 'Adobe InDesign'
    }, {
      class: 'cinema4d',
      link: '/learning/course/cinema4d-basics/about',
      category: '3d-graphics',
      icon: 'https://cdn.cases.media/image/large/sub-landing-course-icon-cinema.png',
      title: 'Cinema 4D'
    }, {
      class: 'webflow',
      link: '/learning/course/webflow-basics/about',
      category: 'web-design',
      icon: 'https://cdn.cases.media/image/large/sub-landing-course-icon-webflow.png',
      title: 'Webflow'
    }, {
      class: 'js',
      link: '/learning/course/javascript-basics/about',
      category: 'frontend',
      icon: 'https://cdn.cases.media/image/large/sub-landing-course-icon-js.png',
      title: 'JavaScript'
    }]);
    return {
      __sfc: true,
      props: props,
      courses: courses,
      SideScroll: SideScroll,
      WebpPicture: WebpPicture
    };
  }
};