<i18n>
{
    "uk": {
        "scrollNext": "Проскролити далі",
        "scrollBack": "Проскролити назад"
    },
    "en": {
        "scrollNext": "Scroll next",
        "scrollBack": "Scroll back"
    }
}
</i18n>

<template>
<div
    :class="{
        'scroll-side-wrapper': true,
        'scroll-no-offset': !offset,
        scrolling
    }"
>


    <button
        @click="scrollNext('left')"
        class="icon-ic-arrow-simple arrow-left btn-primary"
        :class="{
            show: leftArrow
        }"
        :title="$t('scrollBack')"
    >
        <span class="icon-next" />
    </button>

    <div
        class="material-thumbnails side-scroll"
        ref="container"
        :style="{
            'scroll-padding-left' : paddingLeft,
            'scroll-padding-right' : paddingRight
        }"
    >
        <mist
            v-if="mist"
            class="side-scroll-mist inverted show"

        />

        <div
            v-if="paddingLeft"
            class="left-placeholder"
            :style="{
                'flex-basis': paddingLeft
            }"
        />

        <slot />

        <div
            v-if="paddingRight"
            class="right-placeholder"
            :style="{
                'flex-basis': paddingRight
            }"
        />

        <mist
            v-if="mist"
            class="side-scroll-mist show"
        />
    </div>

    <button
        @click="scrollNext('right')"
        class="icon-ic-arrow-simple arrow-right btn-primary"
        :class="{
            show: rightArrow
        }"
        :title="$t('scrollNext')"
    >
        <span class="icon-next" />
    </button>


</div>
</template>

<script>
export default {
    data () {
        return {
            defContainerClassName: 'content-container',
            containerWidth: 0,
            scrollDuration: 700,
            scrolling: false,
            rightArrow: false,
            leftArrow: false,

            items: [],
            prevIndex: 0,
            nextIndex: 0,
            observer: null
        }
    },

    props: {
        offset: {
            type:    Boolean,
            default: true
        },
        containerClassName: String,
        mist: {
            type:    Boolean,
            default: false
        },

        paddingLeft: String,
        paddingRight: String,

        detectVisible: Boolean
    },

    computed: {
        container () {
            if (this.$refs.container) return this.$refs.container;
            let className = this.containerClassName || this.defContainerClassName;
            return document.getElementsByClassName(className)[0];
        }
    },

    mounted () {
        window.addEventListener('resize', this.resizeHandler);
        this.container.addEventListener('scroll', this.scrollHandler);
        this.refresh();
    },

    destroyed () {
        window.removeEventListener('resize', this.resizeHandler);
        this.container.removeEventListener('scroll', this.scrollHandler);

        // if (this.observer) this.observer.disconnect();
    },

    methods: {
        refresh () {
            this.resizeHandler();
            this.observe();
        },

        resizeHandler () {

            // Recalc scroll padding
            this.containerWidth = this.container.offsetWidth;

            this.arrowCheck();
            this.observe();
            if (this.detectVisible) this.setActive();

            if (CSS && CSS.supports('scroll-padding-left: 20px')) {
                if (!getComputedStyle) return true;

                let computedStyle = getComputedStyle(this.container);
                let padRight = parseFloat(computedStyle.getPropertyValue('padding-right'))
                let padLeft  = parseFloat(computedStyle.getPropertyValue('padding-left'))
                this.containerWidth -= padRight + padLeft;
            }
        },

        observe () {
            let items = [];

            this.$el.querySelectorAll('.material-thumbnail, .scroll-item')
            .forEach(e => items.push({
                element: e,
                position: e.offsetLeft
            }));

            this.items = items;
        },

        setActive () {
            let scrollPos = this.$refs.container.scrollLeft;
            let viewboxEnd = scrollPos + this.containerWidth;
            let eventData = [];
            
            this.items.forEach((item, i) => {
                let itemStart = item.position;
                let itemEnd = itemStart + item.element.offsetWidth;
                let visible = false;

                if (itemStart >= scrollPos && itemEnd <= viewboxEnd) {
                    item.element.classList.add('visible');
                    visible = true;
                } else {
                    item.element.classList.remove('visible');
                }

                eventData.push({item, visible, index: i})
            });

            this.$emit('changeVisible', eventData);
        },

        scrollHandler: function() {
            if (this.timeout) clearTimeout(this.timeout);
            
            if (this.detectVisible) this.setActive();

            this.timeout = setTimeout(() => {
                this.arrowCheck();
            }, 100);
        },

        arrowCheck () {
            this.leftArrow  = true;
            this.rightArrow = true;

            let scroll = this.$refs.container.scrollWidth;
            let scrolled = this.$refs.container.scrollLeft

            if (scroll <= this.containerWidth) {
                this.leftArrow  = false;
                this.rightArrow = false;
                return true;
            }

            if (scrolled == 0) this.leftArrow = false;
            if (scrolled+this.$refs.container.offsetWidth >= scroll) {
                this.rightArrow = false;
            }
        },

        scrollNext (direction, newIndex) {
            let scrollPos = this.$refs.container.scrollLeft;
            let index = this.items.findIndex(el => el.position >= scrollPos);

            if (direction == 'right') {
                if (!this.items[index]) return true;
                newIndex = index + 1;
            } else {
                if (index <= 0) {
                    this.$refs.container.scrollTo({
                        left: 0,
                        behavior: 'smooth'
                    });

                    return true;
                }
                newIndex = index - 1;
            }

            this.sideScroll(index, newIndex);
        },

        scrollTo (newIndex) {
            let scrollPos = this.$refs.container.scrollLeft;
            let index = this.items.findIndex(el => el.position >= scrollPos);

            this.sideScroll(index, newIndex);
        }, 

        sideScroll (index, newIndex) {
            // console.log(index, newIndex)
            if (newIndex < 0) {
                return this.$refs.container.scrollTo({
                    left: 0,
                    behavior: 'smooth'
                });
            }

            if (newIndex > this.items.length - 1) {
                this.$refs.container.scrollTo({
                    left: this.container.scrollWidth,
                    behavior: 'smooth'
                });
            }

            let currentRect = this.items[index].element.getBoundingClientRect(),
                newRect     = this.items[newIndex].element.getBoundingClientRect();

            let currentX = currentRect.x;
            let newX     = newRect.x;
            let delta    = newX - currentX;

            this.$refs.container.scrollTo({
                left: this.$refs.container.scrollLeft + delta,
                behavior: 'smooth'
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .right-placeholder,
    .left-placeholder {
        flex-shrink: 0;
        scroll-snap-align: start;
    }

    .right-placeholder {
        scroll-snap-align: end;
    }
</style>
