export const platformPricing = {
    subscriptionReadOnly:   500,
    subscriptionFullAccess: 1000,

    certificate3Months: 2500,
    certificate6Months: 5000,
    certificate12Months: 10000,
};

export const subscriptionIds = [
    "6101170ec90aff6d4fe9577b",
    "64624a54006c56df40edb821",
];

export const theoryOnlyIds = [
    "64624a54006c56df40edb821",
];
