<template>
    <div class="gradient-card">
        <div class="gradient-card-body elevate">
            <slot />
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    .gradient-card {
        padding: 4px;
        background: linear-gradient(
            to right bottom,
            #815BA6 0%,
            #EB4984 25%,
            #F56B61 50%,
            #FA8C40 75%,
            #FDC50B 100%
        );
        border-radius: 8px;
    }

    .gradient-card-body {
        background-color: var(--color-control);
        border-radius: 4px;
    }
</style>
