import TrySection from '~/components/subscription/TrySection.vue';
export default {
  __name: 'Subscription',
  props: {
    locale: String,
    title: String,
    description: String
  },
  setup: function setup(__props) {
    var props = __props;
    var subscriptionStore = useSubscriptionStore();
    return {
      __sfc: true,
      props: props,
      subscriptionStore: subscriptionStore,
      TrySection: TrySection
    };
  }
};