<i18n>
{
    "uk": {
        "title": "Напрямки навчання",

        "description": "Навчіться створювати графічний дизайн, вебсайти, інтерфейси, фронтенд-проєкти та успішні бізнеси в креативних індустріях разом з нами!",

        "ux-ui-web": "UX/UI та вебдизайн",
        "graphic-design": "Графічний дизайн",
        "smm": "SMM",
        "frontend": "Фронтенд розробка",
        "creative-enterpreneurship": "Креативне підприємництво",
        "animation-motion": "Моушндизайн",

        "courses": "немає курсів | {n} курс | {n} курси | {n} курсів"
    },
    "en": {
        "title": "Learning Paths",

        "description": "Learn to create graphic design, websites, interfaces, frontend projects, and successful businesses in creative industries with us!",

        "ux-ui-web": "UX/UI and Web Design",
        "graphic-design": "Graphic Design",
        "smm": "SMM",
        "frontend": "Frontend Development",
        "creative-enterpreneurship": "Creative Entrepreneurship",
        "animation-motion": "Motion Design",

        "courses": "no courses | {n} course | {n} courses"
    }
}
</i18n>


<template>
    <section>

        <div class="page-container">
            <div class="row mb-3 mb-md-4 ta-center">

                <div class="col-12 col-xl-10 offset-xl-1">
                    <h2 class="h3 h2-md mt-0 mb-2 fw-800 fw-md-700">{{ title || $t('title', locale) }}</h2>
                    <div class="h3-md mt-2 fw-400" v-html="description || $t('description', locale)" />
                </div>

            </div>

            <div class="row mb-n2 mb-md-n3">

                <div
                    v-for="(tile, i) in tilesCounted"
                    :key="`direction-${i}`"
                    class="col-12 col-md-6 col-xl-4 mb-2 mb-md-3"
                >
                    <Card
                        class="direction-tile p-2 ta-xl-center"
                        :to="localePath(`/learning/courses/${tile.category}`)"
                    >

                        <div class="direction-icon mr-2 mr-xl-0 mb-xl-2 mx-xl-auto">
                            <WebpPicture
                                class="block-image"
                                :src="tile.icon"
                                :alt="tile.name"
                                width="102"
                                height="102"
                                loading="lazy"
                            />
                        </div>

                        <div class="direction-info">
                            <div
                                class="direction-name fw-700"
                                v-html="tile.name"
                            />
                            <div
                                v-if="tile.desc"
                                class="direction-desc mt-h f-small color-gray-2"
                                v-html="tile.desc"
                            />
                            <div
                                v-else-if="tile.count"
                                class="direction-desc mt-h f-small color-gray-2"
                                v-html="$tc('courses', tile.count, locale)"
                            />
                        </div>

                    </Card>
                </div>

            </div>
        </div>

    </section>
</template>


<script>
    import Card        from '~/components/cards/LinkCard.vue';
    import WebpPicture from '~/components/WebpPicture.vue';

    export default {
        props: {
            tiles:       Array,
            locale:      String,
            title:       String,
            description: String,
        },

        components: {
            Card,
            WebpPicture,
        },

        data () {
            return {
                tilesCounted: []
            };
        },

        created () {
            this.countCourses();
        },

        mounted () {
            this.countCourses();
        },

        computed: {
            tilesComputed () {
                if (Array.isArray(this.tiles) && this.tiles.length > 0) return this.tiles;

                return [
                    {
                        icon: 'https://cdn.cases.media/image/original/cp-directions-icon-ux-ui-product.png',
                        name: this.$t('ux-ui-web', this.locale),
                        category: 'ux-ui',
                    },
                    {
                        icon: 'https://cdn.cases.media/image/original/cp-directions-icon-graphic.png',
                        name: this.$t('graphic-design', this.locale),
                        category: 'graphic',
                    },
                    {
                        icon: 'https://cdn.cases.media/image/original/cp-directions-icon-webdesign.png',
                        name: this.$t('smm', this.locale),
                        category: 'smm',
                    },
                    {
                        icon: 'https://cdn.cases.media/image/original/cp-directions-icon-frontend.png',
                        name: this.$t('frontend', this.locale),
                        category: 'frontend',
                    },
                    {
                        icon: 'https://cdn.cases.media/image/original/cp-directions-icon-creative-ent.png',
                        name: this.$t('creative-enterpreneurship', this.locale),
                        category: 'enterpreneurship',
                    },
                    {
                        icon: 'https://cdn.cases.media/image/original/cp-directions-icon-motion.png',
                        name: this.$t('animation-motion', this.locale),
                        category: 'motion',
                    },
                ];
            },
        },

        methods: {
            async countCoursesByCategory (category) {
                return this.$axios.get('/course/count', {
                    params: {
                        noHidden: true,
                        category,
                    },
                })
                .then(result => {
                    return result.data || {};
                })
                .catch((error) => {
                    console.log(error)
                });
            },

            async countCourses () {
                let tiles = this.tilesComputed;

                let promises = [];

                tiles.forEach(tile => {
                    if ('desc' in tile) return;
                    promises.push(this.countCoursesByCategory(tile.category).then(data => {
                        tile.count = data.count;
                    }));
                });

                let counted = await Promise.all(promises).then(() => {
                    return tiles;
                });

                this.tilesCounted = counted;
            },
        },
    };
</script>


<style lang="postcss" scoped>
    .direction-tile {
        display:         flex;
        flex-flow:       row nowrap;
        align-items:     center;
        justify-content: flex-start;
        height:          100%;

        @media screen and (min-width: 1200px) {
            flex-flow:   column nowrap;
            align-items: stretch;
        }
    }

    .direction-icon {
        flex:   0 0 auto;
        width:  64px;
        height: 64px;

        @media screen and (min-width: 1200px) {
            width:  102px;
            height: 102px;
        }

        :deep(img) {
            display: block;
            height:  auto;
        }
    }

    .direction-name {
        line-height: 1.25;
    }
</style>
